
<template>
    <div class="main-wrapper">
        <div class="top-content" v-if="$route.query.method === 'stuTrain' || $route.query.method === 'teaTrain'">
            <el-breadcrumb separator=">" class="genera-breadcrumb">
                <el-breadcrumb-item>实操训练</el-breadcrumb-item>
                <el-breadcrumb-item>网上交易管理</el-breadcrumb-item>
                <el-breadcrumb-item>答题详情</el-breadcrumb-item>
                <el-breadcrumb-item>查看详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="btn-back">
                <el-button class="backBtn" type="primary" @click="goBack">返回</el-button>
            </div>
        </div>
        <div class="main-wrapper-table">
            <div v-if="exam_content==='库存管理'">
                <el-table :data="deliverAddress"  class="customTable goods-text-table" style="width: 100%; flex: 1; margin-top: 20px;" :max-height="500">
                    <el-table-column  label="考试内容" width="200px">
                        <template slot-scope="scope">
                            <span v-if="scope.$index===0">{{exam_content}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="考试模块" width="200px">
                        <template slot-scope="scope">
                            <span v-if="scope.$index===0">{{module}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品信息" align="left" width="380px">
                        <template slot-scope="scope">
                            <div class="answer" v-if="role===4">
                                <router-link class="a-link" :to="{path:'/judges/teacher/goods/detail', query: {g_id:scope.row.id, type: 1}}" target="_blank">
                                    <img :src="scope.row.goods_img" alt="">
                                    <span class="text">{{scope.row.goods_title}}</span>
                                </router-link>
                            </div>
                            <div class="answer" v-if="role===6">
                                <router-link class="a-link" :to="{path:'/jd/teacher/goods/detail', query: {g_id:scope.row.id, type: 1}}" target="_blank">
                                    <img :src="scope.row.goods_img" alt="">
                                    <span class="text">{{scope.row.goods_title}}</span>
                                </router-link>
                            </div>
                            <div class="answer"  v-if="role===5">
                                <router-link class="a-link" :to="{path:'/goods/detail', query: {g_id:scope.row.id}}" target="_blank">
                                    <img :src="scope.row.goods_img" alt="">
                                    <span class="text">{{scope.row.goods_title}}</span>
                                </router-link>
                            </div>
                            <div class="answer"  v-if="role===3">
                                <router-link class="a-link" :to="{path:'/teacher/goods/detail', query: {g_id:scope.row.id}}" target="_blank">
                                    <img :src="scope.row.goods_img" alt="">
                                    <span class="text">{{scope.row.goods_title}}</span>
                                </router-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="total_inventory" label="总库存" align="center"></el-table-column>
                    <el-table-column prop="scores_inventory" label="得分库存" align="center"></el-table-column>
                    <el-table-column prop="stu_inventory" label="学生库存" align="center"></el-table-column>
                    <el-table-column :label="`得分(${total_score})`" align="center" width="150px">
                        <template slot-scope="scope">
                            <span v-if="scope.row">{{scope.row.score}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table :data="deliverAddressShootList"  class="customTable" style="width: 100%; flex: 1; margin-top: 20px">
                    <el-table-column prop="exam_content" label="考试内容"></el-table-column>
                    <el-table-column prop="module" label="考试模块"></el-table-column>
                    <el-table-column label="学生提交答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewDetailGoodsStu(scope.row)">查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="`得分(${deliver_total_score})`" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.score == 0">0</span>
                            <span v-else-if="scope.row.score > 0">{{ scope.row.score }}</span>
                            <span v-else>学生未提交</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-if="exam_content==='采购管理'">
                <div class="goods-data-table">
                    <div class="data-table-header">
                        <div class="table-header-item" :style="flexList[0]"><span>考试内容</span></div>
                        <div class="table-header-item" :style="flexList[1]"><span>商品信息</span></div>
                        <div class="table-header-item" :style="flexList[2]"><span>展现数</span></div>
                        <div class="table-header-item" :style="flexList[3]"><span>点击数</span></div>
                        <div class="table-header-item" :style="flexList[4]"><span>转化数</span></div>
                        <div class="table-header-item" :style="flexList[5]"><span>利润</span></div>
                        <div class="table-header-item" v-if="role===3 || role===4 || role===6" :style="flexList[6]"><span>满分利润</span></div>
                        <div class="table-header-item" :style="flexList[7]"><span>得分（{{stu_score}}分）</span></div>
                    </div>
                    <div class="data-table-body">
                        <div class="table-body-item">
                            <div class="body-item-top">
                                <div class="item-top-item" :style="flexList[0]">
                                    <span class="text-overflow">{{exam_content}}</span>
                                </div>
                                <div class="item-top-item" :style="flexList[1]"><span></span></div>
                                <div class="item-top-item" :style="flexList[2]"><span>{{total_display_num}}</span></div>
                                <div class="item-top-item" :style="flexList[3]"><span>{{total_click_num}}</span></div>
                                <div class="item-top-item" :style="flexList[4]"><span>{{total_conversion_num}}</span></div>
                                <div class="item-top-item" :style="flexList[5]"><span>{{total_profit}}</span></div>
                                <div class="item-top-item"  v-if="role===3 || role===4 || role===6" :style="flexList[6]"><span>{{full_mark_profit}}</span></div>
                                <div class="item-top-item" :style="flexList[7]"><span>{{stu_score}}</span></div>
                            </div>
                            <div v-if="this.purchasingManagement.length>0">
                                <div class="body-item-bottom"  v-for="(item,index) in purchasingManagement" :key="index">
                                    <div class="item-top-item" :style="flexList[0]"> <span></span></div>
                                    <div class="item-top-item" :style="flexList[1]">
                                        <div class="answer" v-if="role===3">
                                            <router-link class="a-link" :to="{path:'/teacher/goods/detail', query: {g_id:item.goods_id}}" target="_blank">
                                                <img :src="item.img" alt="">
                                                <span class="text">{{item.goods_name}}</span>
                                            </router-link>
                                        </div>
                                        <div class="answer" v-if="role===4">
                                            <div class="a-link">
                                            <!-- <router-link class="a-link" :to="{path:'/judges/teacher/goods/detail', query: {g_id:item.goods_id}}" target="_blank"> -->
                                                <img :src="item.img" alt="">
                                                <span class="text">{{item.goods_name}}</span>
                                            <!-- </router-link> -->
                                            </div>
                                        </div>
                                        <div class="answer" v-if="role===5">
                                            <router-link class="a-link" :to="{path:'/goods/detail', query: {g_id:item.goods_id}}" target="_blank">
                                                <img :src="item.img" alt="">
                                                <span class="text">{{item.goods_name}}</span>
                                            </router-link>
                                        </div>
                                        <div class="answer" v-if="role===6">
                                            <div class="a-link">
                                            <!-- <router-link class="a-link" :to="{path:'/jd/teacher/goods/detail', query: {g_id:item.goods_id}}" target="_blank"> -->
                                                <img :src="item.img" alt="">
                                                <span class="text">{{item.goods_name}}</span>
                                            <!-- </router-link> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-top-item" :style="flexList[2]"><span>{{item.display_num}}</span></div>
                                    <div class="item-top-item" :style="flexList[3]"><span>{{item.click_num}}</span></div>
                                    <div class="item-top-item" :style="flexList[4]"><span>{{item.conversion_num}}</span></div>
                                    <div class="item-top-item" :style="flexList[5]"><span>{{item.profit}}</span></div>
                                    <div class="item-top-item"  v-if="role===3 || role===4 || role===6" :style="flexList[6]"><span></span></div>
                                    <div class="item-top-item" :style="flexList[7]"><span></span></div>
                                </div>
                            </div>
                            <div v-else style="display: flex;justify-content: center;padding: 10px">学生未提交</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="exam_content==='配送管理'">
                <el-table :data="deliverAdd"  class="customTable" style="width: 100%; flex: 1; margin-top: 20px">
                    <el-table-column prop="exam_content" label="考试内容"></el-table-column>
                    <el-table-column prop="module" label="考试模块"></el-table-column>
                    <el-table-column label="学生提交答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewDistribution(scope.row)">查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="`得分(${totalScore1})`" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.stu_score == 0">0</span>
                            <span v-else-if="scope.row.stu_score > 0">{{ scope.row.stu_score }}</span>
                            <span v-else>学生未提交</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table :data="salesReturnAddress"  class="customTable" style="width: 100%; flex: 1; margin-top: 20px">
                    <el-table-column prop="exam_content" label="考试内容"></el-table-column>
                    <el-table-column prop="module" label="考试模块"></el-table-column>
                    <el-table-column label="学生提交答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewDistribution(scope.row)">查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="`得分(${totalScore2})`" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.stu_score == 0">0</span>
                            <span v-else-if="scope.row.stu_score > 0">{{ scope.row.stu_score }}</span>
                            <span v-else>学生未提交</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table :data="afterSalesReturnAddres"  class="customTable" style="width: 100%; flex: 1; margin-top: 20px">
                    <el-table-column prop="exam_content" label="考试内容"></el-table-column>
                    <el-table-column prop="module" label="考试模块"></el-table-column>
                    <el-table-column label="学生提交答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewDistribution(scope.row)">查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="`得分(${totalScore3})`" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.stu_score == 0">0</span>
                            <span v-else-if="scope.row.stu_score > 0">{{ scope.row.stu_score }}</span>
                            <span v-else>学生未提交</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table :data="freightTemplate"  class="customTable" style="width: 100%; flex: 1; margin-top: 20px">
                    <el-table-column prop="exam_content" label="考试内容"></el-table-column>
                    <el-table-column prop="module" label="考试模块"></el-table-column>
                    <el-table-column label="学生提交答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewDistribution(scope.row)">查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="`得分(${totalScore4})`" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.stu_score == 0">0</span>
                            <span v-else-if="scope.row.stu_score > 0">{{ scope.row.stu_score }}</span>
                            <span v-else>学生未提交</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    import {stuOpTrainData,transactionTeaDetail,transactionTeaExamDetail,tranPurchaseTeaExamDetail,tranDistributionTeaExamDetail} from '@/utils/apis'
    export default {
        data() {
            return {
                moduleId:Number(this.$route.query.moduleId) || null,//模板id
                //网店交易管理
                goodsReleaseList: [],
                //库存管理
                deliverAddress: [],
                deliverAddressShootList: [],
                purchasingManagement: [],
                distributionList: [],
                afterSalesReturnAddres: [],//自主售后退货地址管理
                deliverAdd: [],//发货地址管理
                totalScore1:0,
                totalScore2:0,
                totalScore3:0,
                totalScore4:0,
                stu_score:0,
                freightTemplate: [],//运费模板
                salesReturnAddress: [],//备件库退货地址管理
                exam_content:'',
                module:'',
                deliver_total_score:'',
                total_score:'',
                flexList:[
                    {
                        flex:1
                    },
                    {
                        flex:2.60,
                        marginRight:'20px'
                    },
                    {
                        flex:1
                    },
                    {
                        flex:1
                    },
                    {
                        flex:1
                    },
                    {
                        flex:1
                    },
                    {
                        flex:1
                    },
                    {
                        flex:1
                    },
                ],
                role:Number(localStorage.getItem('role')) || null,

            }
        },

        mounted() {
            this.getTrainData()
        },
        methods: {
            //获取网店详情
            async getTrainData(){
                let params = {}
                let res
                if(this.$route.query.method === 'teaTrain'){ //教师训练
                    params.train_id = this.$route.query.trainId;
                    if (this.moduleId===39){
                        //采购管理
                        res = await stuOpTrainData(params)
                        this.exam_content=res.data.exam_content
                        this.stu_score = res.data.stu_score
                        this.total_click_num = res.data.total_click_num
                        this.total_conversion_num = res.data.total_conversion_num
                        this.total_display_num = res.data.total_display_num
                        this.total_profit = res.data.total_profit
                        this.total_score = res.data.total_score
                        this.full_mark_profit = res.data.full_mark_profit
                        if (res.data.stu_answer.length>0){
                            this.purchasingManagement = res.data.stu_answer
                        }else {
                            this.purchasingManagement =[]
                        }
                    }
                    if (this.moduleId===40){
                        //库存管理
                        res = await transactionTeaDetail(params);
                        this.exam_content = res.data.exam_content;
                        this.module = res.data.module;
                        this.total_score = res.data.total_score;
                        if (res.data.goods_info.length>0){
                            this.deliverAddress= res.data.goods_info
                        }else {
                            this.deliverAddress= []
                        }
                        if (res.data.deliver_address){
                            this.deliverAddressShootList.push(res.data.deliver_address)
                            this.deliver_total_score= res.data.deliver_address.total_score;
                        }
                    }
                    if (this.moduleId===41){
                        res = await stuOpTrainData(params)
                        //配送管理
                        if (res.data.deliver_address){
                            this.exam_content = res.data.deliver_address.exam_content;
                            this.module = res.data.deliver_address.module;
                            this.totalScore1= res.data.deliver_address.total_score;
                            this.stu_score= res.data.deliver_address.stu_score;
                            this.deliverAdd.push(res.data.deliver_address)
                        }
                        if (res.data.sales_return_address){
                            this.exam_content = res.data.sales_return_address.exam_content;
                            this.module = res.data.sales_return_address.module;
                            this.totalScore2= res.data.sales_return_address.total_score;
                            this.stu_score= res.data.sales_return_address.stu_score;
                            this.salesReturnAddress.push(res.data.sales_return_address)
                        }
                        if (res.data.after_sales_return_address){
                            this.exam_content = res.data.after_sales_return_address.exam_content;
                            this.module = res.data.after_sales_return_address.module;
                            this.totalScore3= res.data.after_sales_return_address.total_score;
                            this.stu_score= res.data.after_sales_return_address.stu_score;
                            this.afterSalesReturnAddres.push(res.data.after_sales_return_address)
                        }
                        if (res.data.freight_template){
                            this.exam_content = res.data.freight_template.exam_content;
                            this.module = res.data.freight_template.module;
                            this.totalScore4= res.data.freight_template.total_score;
                            this.stu_score= res.data.freight_template.stu_score;
                            this.freightTemplate.push(res.data.freight_template)
                        }
                    }
                }else if(this.$route.query.method === 'stuTrain') { //学生训练
                    params.train_id = this.$route.query.id;
                    res = await stuOpTrainData(params)
                    if (this.moduleId=== 39){
                        this.exam_content=res.data.exam_content
                        this.stu_score = res.data.stu_score
                        this.total_click_num = res.data.total_click_num
                        this.total_conversion_num = res.data.total_conversion_num
                        this.total_display_num = res.data.total_display_num
                        this.total_profit = res.data.total_profit
                        this.total_score = res.data.total_score
                        if (res.data.stu_answer.length>0){
                            this.purchasingManagement = res.data.stu_answer
                        }else {
                            this.purchasingManagement =[]
                        }
                    }
                    if (this.moduleId===40){
                        //库存管理
                        this.exam_content = res.data.exam_content;
                        this.module = res.data.module;
                        this.total_score = res.data.total_score;
                        if (res.data.goods_info.length>0){
                            this.deliverAddress= res.data.goods_info
                        }else {
                            this.deliverAddress= []
                        }
                        if (res.data.deliver_address){
                            this.deliverAddressShootList.push(res.data.deliver_address)
                            this.deliver_total_score= res.data.deliver_address.total_score;
                        }
                    }
                    if (this.moduleId===41){
                        //配送管理
                        if (res.data.deliver_address){
                            this.exam_content = res.data.deliver_address.exam_content;
                            this.module = res.data.deliver_address.module;
                            this.totalScore1= res.data.deliver_address.total_score;
                            this.stu_score= res.data.deliver_address.stu_score;
                            this.deliverAdd.push(res.data.deliver_address)
                        }
                        if (res.data.sales_return_address){
                            this.exam_content = res.data.sales_return_address.exam_content;
                            this.module = res.data.sales_return_address.module;
                            this.totalScore2= res.data.sales_return_address.total_score;
                            this.stu_score= res.data.sales_return_address.stu_score;
                            this.salesReturnAddress.push(res.data.sales_return_address)
                        }
                        if (res.data.after_sales_return_address){
                            this.exam_content = res.data.after_sales_return_address.exam_content;
                            this.module = res.data.after_sales_return_address.module;
                            this.totalScore3= res.data.after_sales_return_address.total_score;
                            this.stu_score= res.data.after_sales_return_address.stu_score;
                            this.afterSalesReturnAddres.push(res.data.after_sales_return_address)
                        }
                        if (res.data.freight_template){
                            this.exam_content = res.data.freight_template.exam_content;
                            this.module = res.data.freight_template.module;
                            this.totalScore4= res.data.freight_template.total_score;
                            this.stu_score= res.data.freight_template.stu_score;
                            this.freightTemplate.push(res.data.freight_template)
                        }
                    }
                    // this.purchasingManagement = res.data
                }else { //考试
                    params.user_id = this.$route.query.id // 学生id
                    params.exam_id = this.$route.query.examId
                    //采购管理
                    if(this.moduleId === 39){
                        res = await tranPurchaseTeaExamDetail(params)
                        this.exam_content=res.data.exam_content
                        this.stu_score = res.data.stu_score
                        this.total_click_num = res.data.total_click_num
                        this.total_conversion_num = res.data.total_conversion_num
                        this.total_display_num = res.data.total_display_num
                        this.total_profit = res.data.total_profit
                        this.full_mark_profit = res.data.full_mark_profit
                        this.total_score = res.data.total_score
                        if (res.data.stu_answer.length>0){
                            this.purchasingManagement = res.data.stu_answer
                        }else {
                            this.purchasingManagement =[]
                        }
                    }
                    //库存管理
                    if (this.moduleId === 40){
                        res = await transactionTeaExamDetail(params)
                        this.exam_content = res.data.exam_content;
                        this.module = res.data.module;
                        this.total_score = res.data.goods_score;
                        if (res.data.goods_info){
                            this.deliverAddress= res.data.goods_info
                        }
                        if (res.data.deliver_address){
                            this.deliverAddressShootList.push(res.data.deliver_address);
                            if (res.data.deliver_address.score) {
                                this.deliver_total_score = res.data.deliver_address.score;
                            } else {
                                this.deliver_total_score = 0;
                            };
                        }
                    }
                    if (this.moduleId===41){
                        res = await tranDistributionTeaExamDetail(params)
                        //配送管理
                        if (res.data.deliver_address){
                            this.exam_content = res.data.deliver_address.exam_content;
                            this.module = res.data.deliver_address.module;
                            this.totalScore1= res.data.deliver_address.stu_score;
                            this.stu_score= res.data.deliver_address.stu_score;
                            this.deliverAdd.push(res.data.deliver_address)
                        }
                        if (res.data.sales_return_address){
                            this.exam_content = res.data.sales_return_address.exam_content;
                            this.module = res.data.sales_return_address.module;
                            this.totalScore2= res.data.sales_return_address.stu_score;
                            this.stu_score= res.data.sales_return_address.stu_score;
                            this.salesReturnAddress.push(res.data.sales_return_address)
                        }
                        if (res.data.after_sales_return_address){
                            this.exam_content = res.data.after_sales_return_address.exam_content;
                            this.module = res.data.after_sales_return_address.module;
                            this.totalScore3= res.data.after_sales_return_address.stu_score;
                            this.stu_score= res.data.after_sales_return_address.stu_score;
                            this.afterSalesReturnAddres.push(res.data.after_sales_return_address)
                        }
                        if (res.data.freight_template){
                            this.exam_content = res.data.freight_template.exam_content;
                            this.module = res.data.freight_template.module;
                            this.totalScore4= res.data.freight_template.stu_score;
                            this.stu_score= res.data.freight_template.stu_score;
                            this.freightTemplate.push(res.data.freight_template)
                        }
                    }

                }
            },
            viewDetailGoodsStu() {
                if (this.$route.query.method ==='teaTrain'){
                    this.$router.push({
                        path: '/trainingCenter/practicalTraining/onlineTradeManage/deliverAddressDetail',
                        query:{
                            trainId:this.$route.query.trainId,
                            method:this.$route.query.method,
                        }
                    })
                }else if(this.$route.query.method ==='teaExam'||this.$route.query.method ==='teaExamTrain'){
                    if (this.role == 6) {
                        this.$router.push({
                            path: '/jd/examCenter/examData/onlineTradeManageDetail/deliverAddressDetail',
                            query:{
                                trainId:this.$route.query.trainId,
                                method:this.$route.query.method,
                                id:this.$route.query.id, // 学生id
                                examId:this.$route.query.examId,
                                moduleId: this.moduleId,
                            }
                        })
                    } else {
                        this.$router.push({
                            path: '/judges/examCenter/examData/onlineTradeManageDetail/deliverAddressDetail',
                            query:{
                                trainId:this.$route.query.trainId,
                                method:this.$route.query.method,
                                id:this.$route.query.id, // 学生id
                                examId:this.$route.query.examId,
                                moduleId: this.moduleId,
                            }
                        })
                    }
                    
                }else {
                    this.$router.push({
                        path: '/student/trainCenter/onlineTradeManage/deliverAddressDetail',
                        query:{
                            id:this.$route.query.id,//训练id
                            method:this.$route.query.method,
                        }
                    })
                }
            },
            //配送管理查看详情
            viewDistribution(row){
                if (this.$route.query.method ==='stuTrain'){
                    this.$router.push({
                        path: '/student/trainCenter/onlineTradeManage/distributionDetail',
                        query:{
                            trainId:this.$route.query.id,
                            method:this.$route.query.method,
                            module:row.module
                        }
                    })
                }else if(this.$route.query.method ==='teaTrain'){
                    this.$router.push({
                        // path: '/examCenter/trainingData/distributionDetail',
                        path: '/trainingCenter/practicalTraining/onlineTradeManage/distributionDetail',
                        query:{
                            trainId:this.$route.query.trainId,
                            method:this.$route.query.method,
                            module:row.module
                        }
                    })
                }else if(this.$route.query.method ==='teaExam'||this.$route.query.method ==='teaExamTrain'){
                    if (this.role == 6) {
                        this.$router.push({
                            path: '/jd/examCenter/examData/distributionDetail',
                            query:{
                                trainId:this.$route.query.trainId,
                                method:this.$route.query.method,
                                id:this.$route.query.id, // 学生id
                                examId:this.$route.query.examId,
                                module:row.module
                            }
                        })
                    } else {
                        this.$router.push({
                            path: '/judges/examCenter/examData/distributionDetail',
                            query:{
                                trainId:this.$route.query.trainId,
                                method:this.$route.query.method,
                                id:this.$route.query.id, // 学生id
                                examId:this.$route.query.examId,
                                module:row.module
                            }
                        })
                    }
                    
                }
            },
            goBack() {
                this.$router.go(-1)
            }

        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 20px 20px 20px;
        .main-wrapper-table{
            display: flex;
            flex-direction: column;
            height: 100%;
            .goods-data-table{
                border: 1px solid #EEEEEE;
                box-sizing: border-box;
                width: 100%;
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                .data-table-header{
                    padding: 0 20px 0 20px;
                    display: flex;
                    background: #EEEEEE;
                    height: 60px;
                    line-height: 60px;
                    box-sizing: border-box;
                    span{
                        color: #666666;
                        font-size: 16px;
                    }
                    .table-header-item{
                        flex: 1;
                        width: 1%;
                        box-sizing: border-box;
                    }
                }
                .data-table-body{
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    .table-body-item{
                        display: flex;
                        flex-direction: column;
                        .body-item-top, .body-item-bottom{
                            padding: 0 20px 0 20px;
                            box-sizing: border-box;
                            width: 100%;
                            display: flex;
                            border-bottom: 1px solid #EEEEEE;
                            .item-top-item, .item-bottom-item{
                                width: 1%;
                                flex: 1;
                                height:100px;
                                display: flex;
                                align-items: center;
                                i{
                                    line-height: 1;
                                    cursor: pointer;
                                    font-size: 18px;
                                    color: #7D7D7D;
                                    margin-right: 4px;
                                }
                                span{
                                    //flex: 1;
                                    font-size: 16px;
                                    color: #333;
                                }
                                img {
                                    width: 80px;
                                    height: 80px;
                                    margin-right: 15px;
                                }
                                .a-link{
                                    height: 80px;
                                    display: flex;
                                    align-items: center;
                                    .text {
                                        flex: 1;
                                        width: 1%;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                        overflow: hidden;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .goods-text-table {
            .answer {
                display: flex;
                align-items: center;
                img {
                    width: 80px;
                    height: 80px;
                    margin-right: 15px;
                }
            }
            .a-link{
                height: 80px;
                display: flex;
                align-items: center;
                .text {
                    flex: 1;
                    width: 1%;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }
        }
        .top-content{
            position: relative;
            .btn-back{
                position: absolute;
                top: -12px;
                right: 20px;
            }
        }
        .dialog-footer{
            text-align: center;
            margin-top: 20px;
        }
    }
</style>